import TextBody from '@cms/components/TextBody'
import { useGlobalContext } from '@cms/hooks/useGlobalContext'
import { FEATURE_SHOW_COOKIE_LIST_TABLE } from '@cms/utils/constants'
import type { ImageWeb } from '@knauf-group/ct-designs/utils/types'
import type { TypeContentTextSkeleton } from '@knauf-group/ct-shared-nextjs/web/contentful/generated-types'
import type { ContentEntry } from '@knauf-group/ct-shared-nextjs/web/utils/types'
import Box from '@mui/material/Box'

import OneTrustCookieManager from '../OneTrustCookieManager'

const TextBodyWrapper: React.FC<ContentEntry<TypeContentTextSkeleton>> = ({ fields }) => {
  const { optionalPageFeatures } = useGlobalContext()
  const isProductImage = Boolean(fields?.productImage)
  return (
    <Box>
      <TextBody
        richTextDocument={fields?.text}
        showSidebarMenu={fields?.sidebarNavigation}
        image={fields?.image?.[0] as ImageWeb}
        imageAlignment={fields?.imageAlignment}
        isProductImage={isProductImage}
      />
      {optionalPageFeatures[FEATURE_SHOW_COOKIE_LIST_TABLE] && <OneTrustCookieManager />}
    </Box>
  )
}

export default TextBodyWrapper
