import type { ReactNode } from 'react'

import type { Styles } from '@knauf-group/ct-designs/utils/types'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import type { TableProps as TablePropsMUI } from '@mui/material/Table'
import MUITable from '@mui/material/Table'

type TableProps = {
  children?: ReactNode
  dataCy: string
} & TablePropsMUI

const Table: React.FC<TableProps> = ({ children, dataCy = 'table', ...props }) => {
  const theme = useTheme()

  const styles: Styles = {
    tableWrapper: {
      width: '100%',
      overflowX: 'auto',
      display: 'block',
      my: '32px',
    },
    table: {
      '& .MuiTypography-root': {
        marginBottom: 0,
      },
      '& .MuiTableRow-root': {
        backgroundColor: theme.palette.common.white,
      },
      '& .MuiTableRow-root:hover': {
        backgroundColor: theme.palette.action.hover,
      },
      '& .MuiTableCell-body': {
        borderBottomColor: theme.palette.grey[20],
        alignItems: 'center',
        padding: '12px 16px',
      },
      '& .MuiTableCell-body > p': {
        color: theme.palette.text.secondary,
        fontSize: theme.fontSize?.base,
      },
      '& .MuiTableCell-head': {
        backgroundColor: theme.palette.grey[10],
        borderBottomColor: theme.palette.grey[20],
      },
      '& .MuiTableCell-head > p': {
        color: theme.palette.text.primary,
        textTransform: 'uppercase',
        fontSize: theme.fontSize?.sm,
        fontWeight: 600,
        lineHeight: theme.fontSize?.base,
      },
    },
  }
  return (
    <Box sx={styles.tableWrapper} data-cy={dataCy}>
      <MUITable sx={styles.table} {...props}>
        {children}
      </MUITable>
    </Box>
  )
}

export default Table
