import { useEffect, useRef, useState } from 'react'

export const useSticky = <Target extends HTMLElement>(topOffset: number) => {
  const ref = useRef<Target>(null)
  const [isSticky, setIsSticky] = useState(false)

  useEffect(() => {
    if (!ref.current) {
      return () => null
    }

    const observer = new IntersectionObserver(
      ([event]) => setIsSticky(event.intersectionRatio < 1),
      {
        threshold: [1],
        rootMargin: `-${topOffset + 1}px 0px 0px 0px`,
      },
    )

    observer.observe(ref.current)

    return () => observer.disconnect()
  }, [topOffset])

  return { ref, isSticky }
}
