import type { ComponentAnalyticsClickProps } from '@cms/analytics/types'
import useNormalizeReference from '@cms/hooks/useNormalizeReference'
import { WebLink } from '@knauf-group/ct-designs/components/core/WebLink'
import { RTLIconStyles } from '@knauf-group/ct-designs/utils/utils'
import ArrowForward from '@mui/icons-material/ArrowForward'

import type { StyledWrappedLinkProps } from './StyledWrappedLink.types'

const StyledWrappedLink: React.FC<StyledWrappedLinkProps> = ({
  styledLinkReference: reference,
  analyticsComponentName,
  dataCy = 'styled-wrapped-link',
  children,
  ...props
}) => {
  const analyticsProps: ComponentAnalyticsClickProps = {
    targetType: 'link',
    cmsComponent: analyticsComponentName,
  }
  const { normalizeReference } = useNormalizeReference()

  const normalizedReference = normalizeReference({
    reference,
    referenceLabelOverride: children,
    analyticsProps,
  })

  return (
    <WebLink
      reference={normalizedReference}
      nextLinkComponent={props.nextLinkComponent}
      data-cy={`${dataCy}-anchor`}
      disabled={props.disabled}
      color="primary"
      endIcon={<ArrowForward sx={RTLIconStyles} />}
      {...props}
    >
      {children || normalizedReference.label}
    </WebLink>
  )
}

export { StyledWrappedLink }
