import { useState } from 'react'

import { action } from '@knauf-group/ct-designs/themeConfigs/rebranding/colors'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import type { SectionsProps } from './TextSidebarMenu.types'

// Andrii suggested not to add the linear gradient to ct-designs as it's only being used in this component
const menuFocusedBg = `linear-gradient(90deg, ${action.selected} 0%, white 100%)`

const Sections = ({ menuEntries, onClose, externalSelectedId, onSelected }: SectionsProps) => {
  const theme = useTheme()
  const [selectedItem, setSelectedItem] = useState(externalSelectedId || null)

  const styles = {
    container: {
      maxWidth: '100%',
    },
    menuList: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: theme.spacing(1),
    },
    menuItem: {
      borderLeft: `2px solid ${theme.palette.divider}`,
      whiteSpace: 'break-spaces',
      '&.Mui-selected': {
        background: menuFocusedBg,
        borderLeft: `2px solid ${theme.palette.secondary.main}`,
        '& p': {
          fontWeight: 600,
        },
      },
      '&': {
        padding: '8px 16px',
      },
    },
  }

  const handleClick = (id: string) => {
    setSelectedItem(id)
    if (onSelected) onSelected(id)
    if (onClose) onClose()
  }

  return (
    <Box sx={[styles.container]}>
      <MenuList sx={[styles.menuList]}>
        {menuEntries.map((entry) => (
          <MenuItem
            component={Link}
            href={entry.link}
            key={entry.id}
            data-cy="menu-item"
            selected={selectedItem === entry.id}
            onClick={() => handleClick(entry.id)}
            sx={[styles.menuItem]}
          >
            <Typography variant="body2" sx={{ padding: '4px 0px' }}>
              {entry.text}
            </Typography>
          </MenuItem>
        ))}
      </MenuList>
    </Box>
  )
}

export default Sections
