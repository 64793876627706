import type { ReactNode } from 'react'
import type React from 'react'
import type { ImageLoader } from 'next/image'
import Image from 'next/image'
import Link from 'next/link'

import useDevicePixelRatio from '@cms/hooks/useDevicePixelRatio'
import { useGlobalContext } from '@cms/hooks/useGlobalContext'
import { useSticky } from '@cms/hooks/useSticky'
import { FALLBACK_IMAGES, SMART_CROP_TOKENS } from '@cms/utils/constants'
import {
  getDevicePixelRatioPriority,
  getImageWithFallback,
  getRenditionImageLoader,
  getSmartCropImageLoaderByTokenName,
  toKebabCase,
} from '@cms/utils/utils'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import type { Document, Text } from '@contentful/rich-text-types'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { ImageWrapper } from '@knauf-group/ct-designs/components/core/ImageWrapper'
import { WEB_CONTAINER_VERTICAL_SPACE } from '@knauf-group/ct-designs/themeConfigs/constants'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material/styles'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'

import RichTextList from '../RichTextList'
import { StyledWrappedLink } from '../StyledWrappedLink'
import type { SidebarEntryProps } from '../TextSidebarMenu'
import TextSidebarMenu from '../TextSidebarMenu/TextSidebarMenu'
import { useStyles } from './TextBody.styles'
import type { TextBodyProps } from './TextBody.types'
import Table from './TextBodyTable'

// empty loader triggers the default one which applies the Optimization API which serves them directly from the app. See https://nextjs.org/docs/pages/building-your-application/optimizing/images#loaders
const getImageLoader = (isProductImage: boolean, devicePixelRatio: number): ImageLoader => {
  const devicePixelRatioPriority = getDevicePixelRatioPriority(devicePixelRatio)

  return isProductImage
    ? getRenditionImageLoader(devicePixelRatioPriority)
    : getSmartCropImageLoaderByTokenName(SMART_CROP_TOKENS.CF_4X3_S, devicePixelRatioPriority)
}

const setImageLoader = (isProductImage, devicePixelRatio) =>
  getImageLoader(isProductImage, devicePixelRatio)

const isEmptyRichText = (richTextDocument: Document) =>
  !richTextDocument ||
  (richTextDocument?.content?.length === 1 &&
    (richTextDocument.content?.[0].content?.[0] as Text).value === '')

type ParagraphProps = {
  children?: ReactNode
}

const Paragraph: React.FC<ParagraphProps> = ({ children }) => (
  <Typography variant="body2" data-cy="text-rich-text-text">
    {children}
  </Typography>
)

const headingsMargin = '16px'
const headingsScrollMarginTop = 'inherit'

const getRichTextRenderOptions = ({ theme }) => ({
  renderNode: {
    [BLOCKS.OL_LIST]: (_node, children) => <RichTextList>{children}</RichTextList>,
    [BLOCKS.UL_LIST]: (_node, children) => <RichTextList>{children}</RichTextList>,
    [BLOCKS.PARAGRAPH]: (_node, children) => <Paragraph>{children}</Paragraph>,
    [BLOCKS.HEADING_1]: (_node, children) => (
      <Typography
        variant="h1"
        data-cy="text-rich-text-headline-one"
        sx={{
          scrollMarginTop: headingsScrollMarginTop,
          marginBottom: headingsMargin,
        }}
      >
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <Typography
        id={toKebabCase(node.content[0].value)}
        sx={{
          color: theme.palette.secondary.main,
          scrollMarginTop: headingsScrollMarginTop,
          marginBottom: headingsMargin,
        }}
        variant="h2"
        data-cy="text-rich-text-headline-two"
      >
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <Typography
        id={toKebabCase(node.content?.[0].value)}
        sx={{
          scrollMarginTop: headingsScrollMarginTop,
          marginBottom: headingsMargin,
        }}
        variant="h3"
        data-cy="text-rich-text-sub-headline-three"
      >
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_4]: (_node, children) => (
      <Typography
        variant="h4"
        data-cy="text-rich-text-sub-headline-four"
        sx={{
          marginBottom: headingsMargin,
          scrollMarginTop: headingsScrollMarginTop,
        }}
      >
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_5]: (_node, children) => (
      <Typography
        variant="h5"
        data-cy="text-rich-text-sub-headline-five"
        sx={{
          marginBottom: headingsMargin,
          scrollMarginTop: headingsScrollMarginTop,
        }}
      >
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_6]: (_node, children) => (
      <Typography
        variant="h6"
        data-cy="text-rich-text-sub-headline-six"
        sx={{
          marginBottom: headingsMargin,
          scrollMarginTop: headingsScrollMarginTop,
        }}
      >
        {children}
      </Typography>
    ),
    [BLOCKS.TABLE]: (_node, children) => (
      <Table dataCy="text-rich-table">
        <TableBody>{children}</TableBody>
      </Table>
    ),
    [BLOCKS.TABLE_ROW]: (_node, children) => <TableRow>{children}</TableRow>,
    [BLOCKS.TABLE_CELL]: (_node, children) => <TableCell>{children}</TableCell>,
    [BLOCKS.TABLE_HEADER_CELL]: (_node, children) => (
      <TableCell variant="head" component="th">
        {children}
      </TableCell>
    ),
    [INLINES.ENTRY_HYPERLINK]: ({ data }, children) => {
      return (
        <StyledWrappedLink
          prefetch={false}
          color="primary"
          endIcon=""
          styledLinkReference={data.target}
          analyticsComponentName="content_text"
          nextLinkComponent={Link}
          dataCy="text-rich-text-link"
          underline="always"
          variant="body2"
          sx={{ padding: 0, lineHeight: '22px' }} // lineHeight should follow paragrapth text one
        >
          {children}
        </StyledWrappedLink>
      )
    },
  },
  preserveWhitespace: true,
})

export const TextBody: React.FC<TextBodyProps> = ({
  richTextDocument,
  showSidebarMenu = false,
  image,
  imageAlignment,
  isProductImage,
}) => {
  const theme = useTheme()
  const { headerHeightWithOffsetTop } = useGlobalContext()
  const styles = useStyles(theme, imageAlignment, isProductImage)

  const { ref, isSticky } = useSticky<HTMLDivElement>(headerHeightWithOffsetTop)

  const hasRichTextTable = richTextDocument?.content?.some(
    (data) => data?.nodeType === BLOCKS.TABLE,
  )

  const { devicePixelRatio } = useDevicePixelRatio()

  let sidebarHeadlines: SidebarEntryProps[]

  if (!isEmptyRichText(richTextDocument)) {
    const textSidebarHeadlineNodes = [BLOCKS.HEADING_2, BLOCKS.HEADING_3]

    const formatRichTextHeadlines = (headlineContent, index): SidebarEntryProps => {
      const text = (headlineContent.content[0] as Text).value
      return {
        id: index + text,
        node: headlineContent.nodeType,
        link: `#${toKebabCase(text)}`,
        text,
      }
    }

    sidebarHeadlines = richTextDocument.content
      .filter((content) => textSidebarHeadlineNodes.indexOf(content.nodeType) !== -1)
      .map(formatRichTextHeadlines)
  }

  const defaultContentGridSize = 8
  const maxGridSize = 12
  const shouldSetAutoMargin = (!showSidebarMenu && !hasRichTextTable) || image // when content is only text(no table) without a sidebar OR when there is an image
  const sectionHeaderTopOffset = 72

  const richTextRenderOptions = getRichTextRenderOptions({
    theme,
  })

  return (
    !isEmptyRichText(richTextDocument) && (
      <Container sx={WEB_CONTAINER_VERTICAL_SPACE}>
        <Grid container data-cy="text">
          {showSidebarMenu && (
            <>
              <Grid
                ref={ref}
                item
                xs={maxGridSize}
                lg={3}
                sx={{
                  position: 'sticky',
                  background: 'white',
                  alignSelf: 'start',
                  zIndex: 1,
                  top: {
                    xs: `calc(${headerHeightWithOffsetTop}px)`,
                    lg: `calc(${headerHeightWithOffsetTop}px + 24px)`,
                  },
                }}
              >
                <TextSidebarMenu menuEntries={sidebarHeadlines} isSticky={isSticky} />
              </Grid>
              <Grid item xs={0} sm={1} />
            </>
          )}
          <Grid
            item
            xs={12}
            md={!showSidebarMenu && hasRichTextTable ? maxGridSize : defaultContentGridSize}
            display={{ xs: 'flex', sm: 'block' }} // this affects the richtext UI with images
            flexDirection={{ xs: 'column', sm: 'row' }} // this affects the richtext UI on mobile
            mx={shouldSetAutoMargin ? 'auto' : 'unset'}
          >
            {image && (
              <Box sx={styles.imageContainer}>
                <ImageWrapper
                  nextImageComponent={Image}
                  image={getImageWithFallback(image, FALLBACK_IMAGES.DEFAULT_4_3_RATIO)}
                  loader={setImageLoader(isProductImage, devicePixelRatio)}
                  sx={styles.image}
                  data-cy="text-image"
                />
              </Box>
            )}
            <Box
              sx={{
                marginBottom: '0px',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                'li > p': {
                  mb: '0px',
                },
                scrollMarginTop: {
                  xs: `calc(${headerHeightWithOffsetTop}px + ${sectionHeaderTopOffset}px + 24px)`,
                  lg: `calc(${headerHeightWithOffsetTop}px + 40px)`,
                },
              }}
              data-cy="text-body"
            >
              {documentToReactComponents(richTextDocument, richTextRenderOptions)}
            </Box>
          </Grid>
        </Grid>
      </Container>
    )
  )
}

export default TextBody
