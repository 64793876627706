import { RTLIconStyles } from '@knauf-group/ct-designs/utils/utils'
import type { Theme } from '@mui/material/styles'

import type { ImageAlignmentProps } from './TextBody.types'

export const useStyles = (
  theme: Theme,
  imageAlignment: ImageAlignmentProps,
  isProductImage: boolean,
) => {
  const marginAround =
    imageAlignment === 'left'
      ? { mr: { sm: `${theme.spacing(2)}`, md: `${theme.spacing(3)}` } }
      : { ml: { sm: `${theme.spacing(2)}`, md: `${theme.spacing(3)}` } }

  const aspectRatio = isProductImage ? 1 : '4/3'

  return {
    imageContainer: {
      float: imageAlignment,
      width: {
        xs: '100%',
        sm: '50%',
      },
      aspectRatio,
      overflow: 'hidden',
      position: 'relative',
      mb: {
        xs: `${theme.spacing(4)}`,
        sm: '12px',
        md: '28px',
      },
      background: theme.palette.background.paper,
      ...marginAround,
    },
    image: {
      width: '100%',
      height: isProductImage ? 'auto' : '100%',
      aspectRatio,
      objectFit: isProductImage ? 'contain' : 'cover',
    },
    arrowIcon: {
      width: '18px',
      height: '18px',
      ...RTLIconStyles,
    },
  }
}
