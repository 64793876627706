import { ScreenMD } from '@knauf-group/ct-designs/utils/hooks/mediaQuery'
import { useTheme } from '@mui/material/styles'

import TextSidebarDesktop from './TextSidebarDesktop'
import type { SidebarProps } from './TextSidebarMenu.types'
import TextSidebarMobile from './TextSidebarMobile'

const TextSidebarMenu = ({ menuEntries, isSticky }: SidebarProps) => {
  const theme = useTheme()
  const isScreenMD = ScreenMD(theme)
  return (
    <>
      {!!menuEntries.length &&
        (isScreenMD ? (
          <TextSidebarMobile
            data-cy="TextSidebarMobile"
            menuEntries={menuEntries}
            isSticky={isSticky}
          />
        ) : (
          <TextSidebarDesktop data-cy="TextSidebarDesktop" menuEntries={menuEntries} />
        ))}
    </>
  )
}

export default TextSidebarMenu
