import type { ReactElement } from 'react'
import React from 'react'

import Box from '@mui/material/Box'

import BulletList from '../BulletList/BulletList'
import { getMappedBulletPointNodes } from '../BulletList/utils'

interface Props {
  children: ReactElement[]
}

const RichTextList = ({ children }: Props) => {
  const listItems = getMappedBulletPointNodes(children)

  return Array.isArray(listItems) && listItems.length > 0 ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
      data-cy="rich-text-list"
    >
      <BulletList data-cy="rich-text-bullet-list" listItems={listItems} />
    </Box>
  ) : null
}

export default RichTextList
