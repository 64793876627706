import Box from '@mui/material/Box'

import Sections from './Sections'
import type { SidebarProps } from './TextSidebarMenu.types'

const TextSidebarDesktop = ({ menuEntries }: Partial<SidebarProps>) => {
  return (
    <Box
      sx={{
        maxWidth: '318px',
        borderRight: '1px solid #001A4C1F',
        marginTop: '8px',
      }}
      data-cy="text-sidebar-desktop"
    >
      <Sections menuEntries={menuEntries} />
    </Box>
  )
}

export default TextSidebarDesktop
