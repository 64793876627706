import { useState } from 'react'
import { useTranslation } from 'next-i18next'

import CloseIcon from '@mui/icons-material/Close'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import { useTheme } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

import Sections from './Sections'
import type { SidebarProps } from './TextSidebarMenu.types'

const TextSidebarMobile = ({ menuEntries, isSticky }: Partial<SidebarProps>) => {
  const [isSectionsVisible, setIsSectionsVisible] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const { t } = useTranslation('cms', { keyPrefix: 'common.sidebar' })
  const theme = useTheme()

  const handleClickOpen = () => {
    setIsSectionsVisible(true)
  }

  const handleClose = () => {
    setIsSectionsVisible(false)
  }

  const handleSelectedItem = (selectedId: string) => {
    setSelectedItem(selectedId)
  }

  const styles = {
    appBar: {
      position: 'relative',
      boxShadow: 'none',
      width: '100%',
      paddingRight: '0 !important',
    },
    toolbar: {
      backgroundColor: 'white',
      padding: '12px 16px',
      color: 'black',
      display: 'grid',
      gridTemplateColumns: '10% 80% 10%',
      justifyItems: 'center',
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    title: {
      gridColumnStart: 2,
      fontWeight: 400,
    },
    iconButton: {
      gridJustifySelf: 'end',
    },
    container: {
      margin: {
        xs: '0 -16px',
        sm: '0 -32px',
      },
      width: 'inherit',
      background: 'white',
    },
    buttonContainer: {
      padding: '16px',
      width: '100%',
      background: 'white',
    },
    stickyContainer: {
      boxShadow: theme.shadows[8],
    },
    button: {
      width: '100%',
    },
    sectionContainer: {
      padding: '8px 16px',
    },
  }

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={isSticky ? [styles.container, styles.stickyContainer] : [styles.container]}
      data-cy="text-sidebar-mobile"
    >
      <Box sx={[styles.buttonContainer]}>
        <Button
          variant="outlined"
          color="primary"
          sx={[styles.button]}
          onClick={handleClickOpen}
        >
          {t('sectionSelectionButton')}
        </Button>
      </Box>
      <Dialog fullScreen onClose={handleClose} open={isSectionsVisible}>
        <AppBar sx={[styles.appBar]}>
          <Toolbar sx={[styles.toolbar]}>
            <Typography variant="h6" sx={[styles.title]}>
              {t('sectionBarLabel')}
            </Typography>
            <IconButton
              sx={[styles.iconButton]}
              edge="end"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box sx={[styles.sectionContainer]}>
          <Sections
            menuEntries={menuEntries}
            onClose={handleClose}
            externalSelectedId={selectedItem}
            onSelected={(id) => handleSelectedItem(id)}
          />
        </Box>
      </Dialog>
    </Container>
  )
}

export default TextSidebarMobile
